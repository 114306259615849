<template>
    <div>
       <v-data-table
            :loading="loading"
            :loading-text="$t('commons.loading')"
            :headers="headers"
            :items="images"
            :items-per-page="pagination.per_page"
            :hide-default-footer="true"
            class="elevation-0 clickable"
            @click:row="details"
            disable-pagination
       >

            <template v-slot:item.name="{ item }">
                <b>{{item.name}}</b>
            </template>

        </v-data-table>

        <v-divider></v-divider>

        <div class="text-center py-3">
            <v-pagination
                v-model="pagination.current"
                :length="pagination.total"
                :total-visible="5"
                @input="getImages()"
                :disabled="isLoading"
            ></v-pagination>
        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "List",

        data() {
            return {
                imageFilter: '',
                filterMenu: false,
                filters: {
                    exact: {},
                    like: {}
                },
                headers: [
                    { text: '#', value: 'id', sortable: false, width: '5%'},
                    { text: this.$t('images.fields.name'), value: 'name', sortable: false, width: '30%'},
                ]
            }
        },

        components: {
        },

        mounted() {
          this.getImages()
        },

        methods: {
            getImages() {
              // this.$store.dispatch('image/fetchImages', this.filters)
            },

            details(v) {
                this.$router.push({
                    name: 'image-profile',
                    params: {
                        id: v.id
                    }
                })
            },

            applyFilter(e) {
                this.$image.dispatch('image/reset')
                this.imageFilter = this.$image.getters['settings/getImageById'](e)
                this.getImages()
            },

            removeImageFilter(event) {
                delete this.filters.exact['id']
                this.imageFilter = ''
                this.getImages()
                event.stopPropagation()
            }
        },

        computed: {
            ...mapGetters({
                isLoading: 'image/loadingState',
                images: 'image/getImages',
                pagination: 'image/getPagination'
            }),

            loading: {
                get() {
                    return this.isLoading
                },
                set(v) {
                    this.$store.dispatch('image/setLoadingStatus', v)
                }
            },
        }
    }
</script>

<style scoped>

</style>
