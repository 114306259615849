<template>
    <v-sheet class="pa-5">

        <list-images></list-images>

    </v-sheet>

</template>

<script>

    import List from '@/components/images/List'

    export default {

        name: "List",

        data () {
            return {
                search: '',
                openFilters: false,
            }
        },

        components: {
            'list-images': List,
        }
    }
</script>

<style lang="scss" scoped>
</style>
